<template>
  <div>
    <div class='p-2 flex flex-row justify-between'>
      <button @click='edit(contact)'
        class='border px-12 py-2 rounded-md border-blue-300 text-sm hover:bg-blue-100 hover:text-semibold'>
        수정
      </button>
      <button v-if='isEditingContact' @click='showDeleteConfirm' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>삭제</button>
    </div>
    <div class='bg-gray-100 p-8 rounded-md'>
      <div class='mb-4'>
        <label class='inline-block w-1/3 uppercase text-gray-600'>Name</label>
        <div class='inline-block text-gray-800'>{{contact.contact_name}}</div>
      </div>
      <div class='mb-4'>
        <label class='inline-block w-1/3 uppercase text-gray-600'>Shareable</label>
        <div class='inline-block text-gray-800'>{{contact.shareable}}</div>
      </div>
      <div class='mb-4'>
        <label class='inline-block w-1/3 uppercase text-gray-600'>Phone Number</label>
        <div class='inline-block text-gray-800'>{{contact.phone_number}}</div>
      </div>
      <div class='mb-4'>
        <label class='inline-block w-1/3 uppercase text-gray-600'>Email</label>
        <div class='inline-block text-gray-800'>{{contact.email}}</div>
      </div>
      <div class='mb-4'>
        <label class='inline-block w-1/3 uppercase text-gray-600'>Address</label>
        <div class='inline-block text-gray-800'>{{contact.address}}</div>
      </div>
      <div class='mb-4'>
        <label class='inline-block w-1/3 uppercase text-gray-600'>Title</label>
        <div class='inline-block text-gray-800'>{{contact.title}}</div>
      </div>
      <div class='mb-4'>
        <label class='inline-block w-1/3 uppercase text-gray-600'>Department</label>
        <div class='inline-block text-gray-800'>{{contact.department}}</div>
      </div>
      <portal to='modals'>
        <contact-edit 
          @updated-contact='cancelEditing' 
          v-if='showEditContact'
          :display-in-modal='true' />
      </portal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ContactEdit   from '@/components/contacts/ContactEdit.vue'
export default {
  name: 'ContactDetails',
  props: ['contact'],
  data () {
    return {
      showEditContact: false,
    }
  },
  components: {
    ContactEdit
  },
  computed: {
    ...mapState('contacts', [
      'drilldownContact'
    ]),
    ...mapGetters('contacts', [
      'isEditingContact',
    ]),
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
    ]),
    ...mapActions('contacts', [
      'editContact',
      'deleteContact',
      'getContactCustomFields',
      'cancelEditing',
    ]),
    edit () {
      this.editContact(this.contact)
      this.showEditContact = true
      this.getContactCustomFields(this.contact.id).then(() => {
        this.$store.dispatch('modalOpen')
      })
    },
    showDeleteConfirm () {
      this.$confirm(`${this.drilldownContact.contact_name} 를(을) 삭제하시겠습니까?`, 'Warning', {
        confirmButtonText: 'delete contact',
        cancelButtonText: 'keep contact',
        type: 'warning'
      }).then(() => {
        this.deleteContact(this.drilldownContact.id).then(() => {
          this.$message({
            type: 'success',
            message: '삭제 완료'
          })
          this.sidepanelClose()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '삭제 취소'
        })
      })
    },
  }
}
</script>
